import './App.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ErrorContext } from './errors';
import {
    QueryClient,
    QueryClientProvider,
    MutationCache,
} from 'react-query';
import { AuthProvider } from "react-oidc-context";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

const useQueryClient = () => {
    const ref = React.useRef(null);
    const errorManager = ErrorContext.useInstance();
    if (ref.current) {
        return ref.current;
    }
    return ref.current = new QueryClient({
        mutationCache: new MutationCache({
            onError: (error) => errorManager.record(error),
        }),
    });
}

const onSigninCallback = (user) => {
    const url = new URL(user.url_state || window.location);
    const query = url.hash.substr(1);
    url.hash = url.search = '';
    window.history.replaceState({query}, '', url);
}

const Index = () => {
    const client = useQueryClient();
    const { redirect_uri, authority, client_id } = window.weezConfig.auth;

    const uri = new URL(window.location);
    uri.search = '';
    uri.hash = '';
    uri.pathname = redirect_uri;

    return <BrowserRouter>
        <Routes>
            <Route path="/bye" element={
                <a href="/">Again</a>
            } />
            <Route path="/*" element={
                <AuthProvider
            onSigninCallback={ onSigninCallback }
            authority={ authority }
            automaticSilentRenew
            client_id={ client_id }
            redirect_uri={ uri.toString() }
            >
                    <QueryClientProvider client={client}>
                        <App />
                    </QueryClientProvider>
                </AuthProvider>
        } />
        </Routes>
    </BrowserRouter>;
}

root.render(<ErrorContext.Provider><Index /></ErrorContext.Provider>);
