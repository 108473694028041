import React from 'react';
import { useAuth } from "react-oidc-context";


const FetchContext = React.createContext();

export const useFetch = () => React.useContext(FetchContext);

export const  Fetch = ({ children }) => {
    const auth = useAuth();
    const fetch = React.useCallback((url, opts) => {
        opts = opts || {};
        const headers = opts.headers = (opts.headers || {});
        headers.Authorization = `${auth.user.token_type} ${auth.user.access_token}`
        return window.fetch(url, opts);
    }, [auth.user.token_type, auth.user.access_token]);

    return <FetchContext.Provider value={ fetch}>{ children }</FetchContext.Provider>;
}
