import React from 'react';
import classNames from "classnames";
import {PluginList} from "./plugins";
import { ErrorBoundary } from "react-error-boundary";
import { useQuery } from 'react-query';
import { Explore } from './models';
import { ShowProject } from './show';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ExploreError, Errors } from './errors';
import { useLocalStoreState } from "./store";
import { useLocationSearch } from "./search";
import { useAuth } from "react-oidc-context";
import { useFetch } from "./fetch";

export const ExploreView = () => {
    const searchInput = React.useRef(null);
    const [search, _setSearch] = useLocationSearch("");
    const setSearch = React.useCallback(({ target }) => _setSearch(target.value), [_setSearch]);
    const clearSearch = React.useCallback(() => {
        _setSearch("")
        searchInput.current.focus();
    }, [_setSearch, searchInput]);

    const auth = useAuth();
    const userID = auth.user.profile.sub;

    const fetch = useFetch();
    const explore = useQuery(['danaa'], () => Explore.query(fetch), {
        retry: false,
        refetchInterval: 60000,
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    });

    const [selected, onSelect] = React.useState(null);

    React.useEffect(() => {
        searchInput.current.focus();
    }, []);

    return (
        <div className="App">
            <div className={ classNames('modal', { 'is-active': selected }) } >
                <div className="modal-background"></div>
                <div className="modal-content">
                    { selected && <ShowProject project={ selected } /> }
                </div>
                <button onClick={ () => onSelect(null) } className="modal-close is-large" aria-label="close"></button>
            </div>
            <section className={ classNames("hero is-primary", {
                'is-success': (explore.data?.lambdaName || '').indexOf('green') !== -1,
            }) } >
                <div className="hero-body">
                    <p className="title ml-1">
                        <CopyToClipboard text={ auth.user.access_token } >
                            <button className="button is-pulled-right is-light is-dark is-small is-outlined">
                                Accounts token
                            </button>
                        </CopyToClipboard>
                        <CopyToClipboard text={ userID } >
                            <button className="button is-pulled-right is-light is-dark is-small is-outlined">
                                User ID {userID.substr(0, 5)}..
                            </button>
                        </CopyToClipboard>
                        <button className={ classNames("button is-white is-pulled-left mr-2", {
                            "is-loading": explore.isFetching,
                        }) }
                            disabled={ explore.isFetching }
                            onClick={ explore.refetch } >
                            <span className="icon">
                                <i className="mdi mdi-cloud-refresh"></i>
                            </span>
                        </button>
                        Weezevent Danaa
                    </p>
                    <p className="subtitle">
                        Deplooyment tool
                        <div className="field has-addons">
                            <div className="control is-expanded has-icon-right">
                                <input className="input" type="search"
                                    autoFocus
                                    ref={ searchInput }
                                    value={ search }
                                    onChange={ setSearch }
                                    placeholder="Project"
                                />
                                <span className="icon is-small is-right">
                                    <i className="fas fa-check"></i>
                                </span>
                            </div>
                            <div className="control">
                                <button className="button" onClick={ clearSearch } disabled={ search === '' }>
                                    <span className="icon">
                                        <i className="mdi mdi-delete"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </p>
                </div>
            </section>
            <ExploreProgressView { ...explore } search={ search } onSelect={ onSelect }/>
        </div>
    );
}

const ExploreProgressView = ({ isSuccess, data, onSelect, search, isError, refetch, error, isFetched }) => {
    if (isSuccess) {
        return <Projects
            search={ search }
            projects={ data.getProjects() }
            onSelect={ onSelect }
            isFetched={ isFetched }
        />;
    }
    if (isError) {
        return <ExploreError
            onClick={ refetch }
            error={ error }
        />;
    }
    return <progress className="progress" />;
}

class FuzzyMatch {
    constructor(pattern) {
        this.minLength = pattern.length;
        this.pattern = Array.from(pattern.toLowerCase());
    }

    match(text) {
        text = text.toLowerCase();
        let lastIndex = 0;
        return this.pattern.every(letter => {
            lastIndex = text.indexOf(letter, lastIndex) + 1;
            return lastIndex;
        })
    }
}

const Projects = ({ projects, search, onSelect, isFetched }) => {
    const [favorites, setFavorite] = useLocalStoreState(
        "favorites",
        value => new Set(value || []),
        value => Array.from(value)
    );
    const [hidden, setHidden] = useLocalStoreState(
        "hidden",
        value => new Set(value || []),
        value => Array.from(value)
    );

    const toggleFavorites = React.useCallback((project) => setFavorite((favorites) => {
        const name = project.name;
        const copy = new Set(favorites);
        if (favorites.has(name)) {
            copy.delete(name);
        } else {
            copy.add(name);
        }
        return copy;
    }), [setFavorite]);

    const addToHidden = React.useCallback(project => setHidden((hidden) => {
        const copy = new Set(hidden);
        copy.add(project.name);
        return copy;
    }), [setHidden]);
    const restoreHidden = React.useCallback((name) => {
        setHidden(
            name
            ? (previous) => {
                const copy = new Set(previous);
                copy.delete(name);
                return copy;
            }
            : new Set());
    }, [setHidden]);

    const _projects = React.useMemo(() => {
        const fuzzy = new FuzzyMatch(search);
        return projects
        .filter(p => fuzzy.match(p.getName()))
        .filter(p => !hidden.has(p.getName()))
        // Sort by favorite, then by name
        .sort((a, b) => 2 * (
            favorites.has(b.name) - favorites.has(a.name)
            + (a.name.toLowerCase() > b.name.toLowerCase())
        ) - 1)
    }, [projects, hidden, favorites, search]);

    return <div>
        <Errors />
        { _projects.length === 0 && search && isFetched
            ? <div className="notification has-text-centered">Nothing</div>
            : _projects
                .map(p => <ExploreProject
                    key={p.name}
                    project={ p }
                    onSelect={ onSelect }
                    isFavorite={ favorites.has(p.name) }
                    onFavorite={ toggleFavorites }
                    onHide={ addToHidden }
                />) }
        <ErrorBoundary fallback="">
            <HiddenNotification hidden={ hidden } onClear={ restoreHidden } />
        </ErrorBoundary>
    </div>
}

const HiddenNotification = ({ hidden, onClear }) => {
    const [shown, setShown] = React.useState(false);
    const toggle = React.useCallback(() => setShown(shown => !shown), [setShown]);

    if (hidden.size === 0)  {
        return null;
    }

    return <div className="notification">
        <span>
            Not showing {hidden.size} hidden projects
        </span>
        <span className="icon">
            <i className="mdi mdi-broom"></i>
        </span>
        <button className="button is-text is-small" onClick={ () => onClear() }>
            Restore
        </button>
        <button className="button is-pulled-right is-ghost" onClick={ toggle }>
            <span className="icon">
                <i className={classNames("mdi", {
                    "mdi-arrow-down-drop-circle": !shown,
                    "mdi-arrow-up-drop-circle": shown,
                })} ></i>
            </span>
        </button>
        { shown && <div className="tags">
            {Array.from(hidden).map(name => <span className="tag is-dark" key={name}>
                { name }
                <button className="delete is-small" onClick={ () => onClear(name) }></button>
            </span>) }
        </div> }
    </div>;
}

export const ExploreProject = ({project, onSelect, onFavorite, isFavorite, onHide}) => {
    return <div className="box">
        <h2 className="title is-info is-large">
            <button className="button is-ghost" onClick={ () => onSelect(project) } >
                <span className="icon">
                    <i className="mdi mdi-magnify-expand"></i>
                </span>
            </button>
            <button
                disabled={ isFavorite }
                className="button is-ghost is-pulled-right"
                onClick={ () => onHide(project) } >
                <span className="icon">
                    <i className="mdi mdi-broom"></i>
                </span>
            </button>
            <button className="button is-ghost is-pulled-right" onClick={ () => onFavorite(project) } >
                <span className="icon">
                    <i className={ classNames("mdi", {
                        "mdi-pin": isFavorite,
                        "mdi-pin-outline": !isFavorite,
                    }) }></i>
                </span>
            </button>
            { project.name }
        </h2>
        <ErrorBoundary fallback={ <div>:cpt:</div>}>
            <div className="columns is-multiline is-desktop">
                <div className="column is-full is-one-third-desktop">
                    <ExploreEnvironment environment={ project.repo } />
                </div>
                { project.getEnvironments().map(e =>
                    <div className="column">
                        <ExploreEnvironment environment={ e } key={ e.getName() } />
                    </div> ) }
            </div>
        </ErrorBoundary>
    </div>
}

const packageSortFN = (pA, pB) => (pA.branch < pB.branch) - (pA.branch > pB.branch);

const ExploreEnvironment = ({
    environment,
}) => {
    const showVersionName = environment.getVersions().length >= 1;
    const showPackageName = environment.getPackages().length >= 1;

    return <div className="card p-2">
        <h4 className="title is-4">
            { environment.getName() }
            { environment.getPackages()
                .sort(packageSortFN)
                .map((packag, i) => <a key={ i } href={ packag.getGithubLink() }>
                    <span className={classNames('tag', 'is-pulled-right', {
                        'is-primary': packag.branch === 'master' || packag.branch === 'latest',
                        'is-info': packag.branch === 'uat',
                    }) }
                    data-tooltip={ showPackageName ? packag.getName() : undefined}
                    >
                        { packag.toString() }
                    </span>
                </a>) }
            { environment.getVersions().map((version, i) => <a key={ i } href={ version.getGithubLink() }>
                <span className={classNames('tag', 'is-pulled-right is-success', {
                    'is-primary': version.branch === 'uat',
                    'is-warning': !version.isLatest(),
                }) }
                data-tooltip={ showVersionName ? version.getName() : undefined}
                >
                    #{ version.version }
                </span>
            </a>) }
        </h4>
        <div className="block">
            <div className="buttons">
                { environment.getPackages().filter(
                    pkg => pkg.getBranchName() !== 'master'
                ).map((pkg, i) =>
                    <a rel="noreferrer" className="button is-link" key={ i }
                    href={ pkg.getGithubDiffLink() } target="_blank" >
                        <span>
                            Diff {pkg.getBranchName()}
                        </span>
                        <span className="icon">
                            <i className="mdi mdi-github"></i>
                        </span>
                    </a>
                ) }
                { environment.getVersions()
                    .filter(v => !environment.project.repo.isLatest(v))
                    .map((version, i) =>
                        <a rel="noreferrer" className="button is-link" key={ i }
                        href={ version.getGithubDiffLink() } target="_blank" >
                            <span
                                data-tooltip={ version.toString() } >
                                Diff
                            </span>
                            <span className="icon">
                                <i className="mdi mdi-github"></i>
                            </span>
                        </a>) }
                <PluginList buttons plugins={ environment.getPlugins() } />
            </div>
        </div>
    </div>
}
