import { useLongPress } from 'use-long-press';
import React from 'react';
import classNames from "classnames";

function longPressSM(state, action) {
    switch(action) {
        case 'start':
            return {
                ...state,
                active: true,
            };
        case 'finish':
            return {
                ...state,
                // Clears the progress bar
                active: false,
                cancelTimeout: state.counter > 0 && setTimeout(() => state.cancel(), 10 * 1000),
                // Go into ready step if it's not the first click
                ready: state.counter > 0,
                // Increment the counter if it's the first click
                counter: state.counter + (state.counter === 0),
            };
        case 'click':
            if (state.ready) {
                // A click event is dispatched after the long press; catch this
                // event with preclick to catch the later real click event
                if (!state.preclick) {
                    return {
                        ...state,
                        preclick: true,
                    };
                }
                if (state.cancelTimeout) {
                    clearTimeout(state.cancelTimeout);
                }
                state.onClick();
                return {
                    ...state,
                    cancelTimeout: undefined,
                    preclick: undefined,
                    active: false,
                    ready: false,
                };
            }
            return state;
        case 'cancel':
            return {
                ...state,
                cancelTimeout: undefined,
                active: false,
                ready: false,
            };
        case 'pressed':
            // On the first click, trigger the callback
            if (state.counter === 0) {
                state.onClick();
            }
            return state;
        default:
            return state;
    }
};

export const LongPressButton = ({ onClick, className, ...props }) => {
    // delay in seconds
    const [state, dispatch] = React.useReducer(longPressSM, {
        counter: 0,
        duration: 1,
        active: false,
        ready: false,
    });
    state.onClick = onClick;
    state.cancel = () => dispatch('cancel');

    const longpress = useLongPress(() => dispatch('pressed'), {
        threshold: state.duration * 1000,
        onStart: () => dispatch('start'),
        onCancel: () => dispatch('cancel'),
        onFinish: () => dispatch('finish')
    });
    const args = state.ready ? { onClick: () => dispatch('click') }  : longpress();

    return <button {...args} className={
        classNames(className, "button-progress", {
            "button-progress-active": state.active,
            "blink": state.ready,
        })
    } style={ {transitionDuration: state.ready ? '0s' : `${state.duration}s` } } {...props} />;
}

