import React from 'react';

class _StoredState {
    constructor(name, value, serialize) {
        this.name = name;
        this.serialize = serialize;
        this.value = value;
    }

    _write(input) {
        // if input is a function, feed it the current state
        const value = typeof input === "function" ? input(this.value): input;
        // Persist the value
        const persistableValue = this.serialize(value);
        window.localStorage.setItem(this.name, JSON.stringify(persistableValue));
        // Returns a copy with the new value
        return new _StoredState(this.name, value, this.serialize);
    }
}

class StoredState extends _StoredState {
    static initialize(opts) {
        // Create a new instance
        return new StoredState(opts.name, opts.unserialize, opts.serialize);
    }
    static write(instance, input) {
        // Generate a copy of the instance
        return instance._write(input)
    }
    static _read(name) {
        // Read the current value from the storage
        try {
            return JSON.parse(window.localStorage.getItem(name));
        } catch {
            return undefined;
        }
    }

    constructor(name, unserialize, serialize) {
        const value = unserialize(StoredState._read(name));
        super(name, value, serialize);
    }

}

export function useLocalStoreState(name, unserialize, serialize) {
    const [state, write] = React.useReducer(StoredState.write, {
        name,
        unserialize,
        serialize,
    }, StoredState.initialize);

    return [state.value, write];
}
