import { useQuery } from 'react-query';
import classNames from "classnames";
import React from 'react';
import { Aaaarrrr } from './models';
import { ExploreError } from './errors';
import { ErrorBoundary } from "react-error-boundary";
import levenshtein from 'js-levenshtein';
import { useFetch } from "./fetch";

const _FindAaaarrrr = /arn:dana:aaaarrrr:\w+:(\w+):(\w+)@(.+)\/(.*)/;
const FindAaaarrr = (env) => {
    if (!env) {
        return null;
    }
    const arns = env.dana || [];
    for(const arn of arns) {
        const match = _FindAaaarrrr.exec(arn);
        if (match) {
            return {
                status: match[2],
                start: new Date(match[3]),
                stop: match[4] ? new Date(match[4]) : null,
            };
        }
    }
    return null;
}

const isToday = date => !(
    Math.floor(new Date().getTime() / (24 * 3600 * 1000))
    - Math.floor(date.getTime() / (24 * 3600 * 1000))
);
const format = date => isToday(date) ? date.toLocaleTimeString() : date.toLocaleString();

const statusText = (status, start, stop) => {
    if (!stop) {
        return `still ${status} since ${format(start)}`;
    }
    return `${status} ${isToday(stop) ? "on" : "at"} ${format(stop)}`;
};

const StatusIcon = ({direction, status, start, stop}) => {
    const success = status === 'RUNNING' ? '-circle-outline' : status === 'SUCCEEDED' ? '' : '-off';

    return <span className="icon"
        data-tooltip={ statusText(status, start, stop) }>
        <i className={ `mdi mdi-${direction}${success}` }></i>
    </span>;
}

export const AaaaRrrrStatus = ({ project }) => {
    const projectEnv = project.data;
    const restore = FindAaaarrr(projectEnv.restore);
    const archive = FindAaaarrr(projectEnv.archive);

    return <>
        { archive && <StatusIcon direction="upload" {...archive} /> }
        { restore && archive &&
            <DeltaWarning start={restore.start} stop={archive.stop} />}
        { restore && <StatusIcon direction="download" {...restore} /> }
    </>;
};

const DeltaWarning = ({start, stop}) => {
    if (start > stop) {
        return null;
    }
    return <span className="icon" data-tooltip={
        `Restore started at ${format(start)}` +
        ` before archive ended at ${format(stop)}`} >
        <i className="mdi mdi-timer-sand"></i>
    </span>
}

const DDate = ({ date }) => date.toLocaleDateString();

const context = React.createContext({});

export const AaaarrrrView = ({ token }) => {
    const [highlight, setHighlight] = React.useState('');

    const fetch = useFetch();
    const { isError, data, refetch, error, isSuccess } = useQuery(['aaaarrrr'], () => Aaaarrrr.query(fetch, token), {
        retry: true,
    });

    if (isError) {
        return <ExploreError
            onClick={ refetch }
            error={ error }
        />;
    }

    if (!isSuccess) {
        return <progress className="progress" />;
    }

    const days = new Array(data.days).fill(null).map((e, i) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        today.setDate(today.getDate() - i)
        return today;
    });

    const calendars = data.getCalendars();
    return <context.Provider value={ data }>
        <div className="table-container">
            <table className="table is-hoverable is-fullwidth">
                <thead>
                    <tr>
                        <th>Project</th>
                        <th>State machine</th>
                        { days.map(day => <th key={ day.toString() } className="date">
                            <span>
                                <DDate date={ day } />
                            </span>
                        </th>) }
                    </tr>
                </thead>
                <tbody>
                    {calendars.map( calendar => <AaaarrrrRowView
                        calendar={ calendar }
                        highlight={ setHighlight }
                        highlighted={ highlight }
                    />) }
                </tbody>
            </table>
        </div>
    </context.Provider>;
}

const AaaarrrrRowView = ({ calendar, ...props }) => {
    const machines = calendar.getMachines()
    return <ErrorBoundary fallback={ <tr>
        <th>
            {calendar.getName()}
        </th>
    </tr> }>
        <tr>
            <th rowspan={ machines.length } >
                {calendar.getName()}
            </th>
            <AaaarrrrLineView machine={ machines[0] } { ...props } />
        </tr>
        { machines.slice(1).map(machine => <tr>
            <AaaarrrrLineView machine={ machine } { ...props } />
        </tr>) }
    </ErrorBoundary>;
}

const AaaarrrrLineView = ({ machine, ...props }) => {
    return <>
        <th>{ machine.getName() }</th>
        { machine.getDays().map(ex => <AaaarrrrCellView execution={ ex } {...props} />) }
    </>;
}
const statusClass = {
    skipped: 'mdi-minus has-text-info',
    succeeded: 'mdi-check-circle has-text-success',
    aborted: 'mdi-stop has-text-danger',
    failed: 'mdi-skull has-text-danger',
};

const matches = (a, b) => {
    if (!a || !b) {
        return false;
    }
    if (a === b) {
        return true;
    }
    return levenshtein(a, b) < 100;
}

const AaaarrrrCellView = ({ execution, highlight, highlighted }) => {
    const aaaarrrr = React.useContext(context);
    const executionDetails = aaaarrrr.describeExecution(execution);
    const cause = executionDetails.cause;

    const isHighlighted = React.useMemo(() => matches(cause, highlighted), [cause, highlighted]);

    return <td>
        <abbr
            title={ cause }
            onClick={ () => highlight(highlighted === cause ? "" : cause) }
        >
            <i className={ classNames("mdi", statusClass[executionDetails.status], {
                glow: isHighlighted,
            }) }></i>
        </abbr>
    </td>;
}
