import classNames from "classnames";
import React from 'react';
import { Link, Routes, Route } from "react-router-dom";

const TabLi = ({ to, isActive, children }) => {
    return <li className={ classNames({
        "is-active": isActive,
    }) }>
        <Link to={ to }>{ children }</Link>
    </li>;
}
// Virtual component used by Tabs to identify its tabs
export const Tab = ({ children, title }) => null;

export const Tabs = ({ children }) => {
    const tabs = React.Children.toArray(children);

    return <div>
        <div className="tabs">
            <ul>
                {tabs.map((tab, i) => <TabLi
                    key={ i }
                    to={ tab.props.path || tab.props.title }
                >
                    { tab.props.title }
                </TabLi>)}
            </ul>
        </div>
        <Routes>
            {tabs.map(tab => <Route path={ tab.props.path || tab.props.title } element={ tab.props.children } />) }
        </Routes>
    </div>
}
