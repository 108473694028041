import React from 'react';
import { Tabs, Tab } from './tabs';
import { ExploreView } from './explore';
import { AaaarrrrView } from './aaaarrrr';
import { useAuth } from "react-oidc-context";
import { Fetch } from "./fetch";

const Logout = () => {
    const auth = useAuth();
    const texts = [
        'Au revoir',
        'Sayonara',
        'Goodbye',
        'Auf wiedersehen',
        'Tchüss',
        'Adiós',
        'Arrivederci',
    ];
    const text = texts[Math.floor(Math.random() * texts.length)];

    return <button className="button is-danger" onClick={ () => auth.signoutSilent({
        url_state: '/bye',
    }) }>
        { text }
    </button>
}

const App = () => {
    const auth = useAuth();
    if (auth.error) {
        return <article className="message is-danger">
            <div className="message-header">
                <p>Authentication Error</p>
            </div>
            <div className="message-body">
                <pre>{ String(auth.error) }</pre>
            </div>
        </article>;
    }
    if (auth.isLoading) {
        return 'Loading in progress';
    }
    if (auth.isAuthenticated) {
        return (
            <Fetch>
                <Tabs>
                    <Tab path="/" title="Deploy">
                        <ExploreView />
                    </Tab>
                    <Tab title="Aaaarrrr">
                        <AaaarrrrView />
                    </Tab>
                    <Tab title="Sayonara">
                        <Logout />
                    </Tab>
                </Tabs>
            </Fetch>
        );
    }
    return <GoToAuth />
}

const GoToAuth = () => {
    const auth = useAuth();
    const uri = window.location.toString();

    React.useEffect(() => {
        auth.signinRedirect({url_state: uri});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
}

export default App;
