/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classNames from "classnames";
import {PluginList} from "./plugins";

const Raw = ({ project }) => {
    return <pre>{JSON.stringify(project.data, undefined, 4)}</pre>;
}

const PluginView = ({ plugins }) => {
    return <div>
        <div className="buttons">
            <PluginList buttons plugins={ plugins } />
        </div>
        <PluginList plugins={ plugins } />
    </div>;
}


export const ShowProject = ({ project }) => {
    const [selected, setSelected] = React.useState(project.getEnvironments()[0]);

    return <div className="box">
        <div className="tabs is-toggle is-fullwidth">
            <ul>
                { project.getEnvironments().map(e => <li className={ classNames({
                'is-active': selected === e,
            }) } key={e.getName()}>
                    <a onClick={ () => setSelected(e) }>
                        { e.getName()}
                    </a></li>) }
                <li className={ classNames({
                    'is-active': selected === null,
                }) }>
                    <a onClick={ () => setSelected(null) }>
                        <em>RAW</em>
                    </a>
                </li>
            </ul>
        </div>
        { selected
            ? <PluginView plugins={ selected.getPlugins() } />
            : <Raw project={ project } /> }
    </div>;
};
