import React from 'react';
import { useQuery, useMutation, useIsMutating } from 'react-query';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import classNames from "classnames";
import { LongPressButton } from "./longpress";
import { Plugin } from "./models";
import { useFetch } from "./fetch";
import { AaaaRrrrStatus } from "./aaaarrrr";

const ShowVersion = ({ version }) => {
    if (version.indexOf('arn:') === -1) {
        return <strong>tag {version}</strong>;
    }

    const arn = version.split(':');
    return <span>
        version <strong>{arn[5]}</strong> (tag <em>{arn[4]}</em>)
    </span>
};

const PromoteImage = ({ plugin }) => plugin.getARNList().map(arn => <InnerPromoteImage
    arn={arn}
    plugin={plugin}
    />
);

const InnerPromoteImage = ({ arn, plugin }) => {
    const packages = plugin.environment.getPlugin('package');

    const fetch = useFetch();
    const [selector, setSelector] = React.useState({ value: null });
    const [tagName, setTagName] = React.useState({value: null});
    const mutation = useMutation(() => plugin.invoke(fetch, arn, {
        tag: tagName.value,
        selector: selector.value,
    }));

    if (!packages) {
        return null;
    }

    const i1 = arn.lastIndexOf(':');
    const i2 = arn.indexOf('/', i1);
    const name = arn.substring(i2 + 1);

    const versions = (packages?.getARNList() || [])
        .map(arn => {
            const bits = arn.split(':');
            if (bits[2] !== 'ecr' || bits[5] === '0' || bits[3] !== name) {
                return null;
            }
            return {
                name: bits[4],
                number: bits[5],
                arn,
            };
        })
        .filter(Boolean);

    return <div className="field danaa-promote-image">
        {
            plugin.getARNList().length > 1 &&
            <label className="label">{ arn.split('/')[1] }</label>
        }
        <div className="field has-addons">
            <div className="control">
                {
                    tagName.custom
                    ? <input type="text" className="input" autoFocus
                        onKeyUp={ tagName.value ? undefined : (e => e.code === 'Backspace' ? setTagName({
                            value: 'latest',
                            touched: false,
                            custom: false
                        }) :  null)}
                        onChange={ (e) => setTagName({
                            value: e.target.value,
                            touched: true,
                            custom: true
                        }) } />
                    : <div className="select" >
                        <select value={ tagName.value } onChange={ (e) => setTagName({
                            value: e.target.value,
                            touched: true,
                            custom:e.target.value === "",
                        }) } >
                            <option disabled value="">Tag</option>
                            { versions
                                .map(version => <option key={ version.name } value={ version.name }>
                                    { version.name }
                                </option>) }
                            <option value="">Custom</option>
                        </select>
                    </div>
                }
            </div>
            <div className="control">
                {
                    selector.custom
                    ? <input type="text" className="input" autoFocus
                        onKeyUp={ selector.value ? undefined : (e => e.code === 'Backspace' ? setSelector({
                            value: null,
                            touched: false,
                            custom: false
                        }) :  null)}
                        onChange={ (e) => setSelector({
                            value: e.target.value,
                            custom: true,
                            touched: true,
                        }) } />
                    : <div className="select" >
                        <select value={ selector.value } onChange={ (e) => setSelector({
                            value: e.target.value,
                            custom: e.target.value === '',
                            touched: true,
                        }) } >
                            <option disabled value="">Image</option>
                            { versions
                    .map(version => <option key={ version.arn } value={ version.arn }>
                        { version.name } ({version.number})
                    </option>) }
                            <option value="">Custom</option>
                        </select>
                    </div>
                }
            </div>
            <div className="control">
                <button className={ classNames("button is-primary", {
            "is-loading": mutation.isLoading
        }) }
        onClick={ mutation.mutate }
        disabled={ mutation.isLoading || selector.value === null } >
                    <span>
                        Set {tagName.value}
                    </span>
                    <span className="icon">
                        <i className="mdi mdi-arrow-u-down-left"></i>
                    </span>
                </button>
            </div>
        </div>
        {
            (selector.touched || tagName.touched)
            && Boolean(selector.value)
            && <p className="help">Add the tag <strong>{tagName.value}</strong>&nbsp;
                to the image with <ShowVersion version={selector.value} /></p>
        }
    </div>
}

const extractDeployProgress = new RegExp('(\\d+)\\/(\\d+)@\\+(\\d+)@-(\\d+)');

const DeployProgress = ({ plugin, arn }) => {
    const [closed, setClosed] = React.useState(false);

    if (closed) {
        const name = arn.substr(arn.lastIndexOf('/') + 1);
        return <div className="field">
            <span className="tag is-primary">{ name }</span>
            <span className="tag is-dark is-pulled-right">OK</span>
            <progress className="progress" value="1" max="1"></progress>
        </div>;
    }
    return <DeployProgressBar
        plugin={ plugin }
        arn={ arn }
        onClose={ () => setClosed(true) }
    />;
};

const DeployProgressBar = ({ plugin, arn, onClose }) => {
    const fetch = useFetch();
    const progress = useQuery(
        ['plugin', 'DeployProgress', arn],
        () => plugin.invoke(fetch,arn), {
            refetchInterval: 2500 + Math.random() * 800
        },
    );
    const name = arn.substr(arn.lastIndexOf('/') + 1);
    const match = progress.isSuccess ? extractDeployProgress.exec(progress.data) : [];

    const deployed = parseInt(match[1]) || 0;
    const total = parseInt(match[2]) || 0;
    const pending = parseInt(match[3]) || 0;
    const failed = parseInt(match[4]) || 0;

    React.useEffect(() => {
        if (total > 0 && deployed === total) {
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deployed, total]);

    return <div className="field">
        <span className="tag is-primary">{ name }</span>
        { pending ? <span className="tag is-warning">{ pending }</span> : null }
        { failed ? <span className="tag is-error">{ failed }</span> : null }
        <span className="tag is-dark is-pulled-right">{ total }</span>
        <progress className="progress" value={ deployed } max={ total }>
        </progress>
    </div>;
};

const DeployError = ({ error, onSelect }) => {
    if (error.value.version) {
        return <span>
            Unknow version {" "}
            <strong className="has-text-danger">{error.value.version}</strong>
            { error.value.alternatives && <>
                {" "} maybe {" "}
                { error.value.alternatives.map(e =>
                    <label key={ e } onClick={ () => onSelect(e) } className="has-text-link">
                        {e}
                    </label>) } </>}
        </span>
    }
    return error.toString();
}

const FullDeployRow = ({ arn, plugin }) => {
    const detail = plugin.getARNList().length > 1;
    const fetch = useFetch();
    const mutation = useMutation(() => plugin.invoke(fetch, arn, { version }));
    const [version, setSelector] = React.useState('');

    const deploymentPlugin = plugin.environment.getPlugin('deployment');

    if (mutation.isSuccess && deploymentPlugin) {
        return deploymentPlugin.getARNList().map(arn => <DeployProgress
            plugin={ deploymentPlugin }
            arn={ arn }
        />);
    }

    return <div className="field" key={ arn }>
        <div className="field has-addons">
            <div className="control is-expanded">
                <input
                    className={ classNames("input", { "is-danger": mutation.error }) }
                    type="text"
                    value={ version }
                    onChange={ ev => setSelector(ev.target.value) }
                    disabled={ mutation.isLoading }
                    placeholder="latest" />
            </div>
            <div className="control">
                <button className={ classNames("button is-danger", {
                "is-loading": mutation.isLoading
            }) }
                disabled={ mutation.isLoading || !version }
                onClick={ mutation.mutate }
            >
                    <span data-tooltip={ detail ? arn.split(':').pop() : undefined  } >
                        Deploy
                        { detail ? '   ' + arn.split(':')[2] : '' }
                    </span>
                    <span className="icon">
                        <i className="mdi mdi-restart"></i>
                    </span>
                </button>
            </div>
        </div>
        { mutation.error && <p className="help is-danger">
            <DeployError error={ mutation.error } onSelect={ setSelector } />
        </p> }
    </div>;
}

const FullDeploy = ({ plugin }) => {
    return plugin.getARNList().map(arn => <FullDeployRow
        key={ arn }
        arn={ arn }
        plugin={ plugin }
    />);
};

const DeployARN = ({ plugin, arn }) => {
    const fetch = useFetch();
    const mutation = useMutation(() => plugin.invoke(fetch, arn));
    const detail = plugin.getARNList().length > 1;
    const version = plugin.environment.findVersion(arn);

    return <LongPressButton key={ arn } className={ classNames("button is-danger", {
            "is-loading": mutation.isLoading,
            "is-light": !mutation.isLoading && version && plugin.environment.project.repo.isLatest(version),
        }) }
        onClick={ mutation.mutate }
        disabled={ mutation.isLoading } >
        <span data-tooltip={ detail ? arn.split(':').pop() : undefined  } >
            Deploy
            { detail ? '   ' + arn.split(':')[2] : '' }
        </span>
        <span className="icon">
            <i className="mdi mdi-restart"></i>
        </span>
    </LongPressButton>;
}

const Deploy = ({ plugin }) => {
    return plugin.getARNList().map(arn => <DeployARN key={ arn } plugin={ plugin } arn={ arn } />);
};

const usePolling = (pollingID, pollingFunction) => {
    return useQuery(pollingID, async () => {
        const polling = await pollingFunction();
        if (!polling) {
            throw new Error('not ready');
        }
        return polling;
    }, {
        enabled: Boolean(pollingFunction),
        retry: true,
    });
}

const PowerLevel = ({ value }) => {
    if (!value) {
        return 'Default';
    }
    if (value > 10) {
        return '> 9000';
    }
    return String(value);
}

const ShellWithPowerlevel = ({ plugin, }) => {
    const [powerLevel, setPowerLevel] = React.useState(0);
    const isMutating = useIsMutating({
        mutationKey: ['shell', plugin.environment.getName(), plugin.environment.project.getName()],
    })

    return <div>
        <label className="label">Shell</label>
        <div className="field has-addons" >
            <div className="control has-icons-left ">
                <input
                    disabled={ isMutating > 0 }
                    data-tooltip="Use the default resources"
                    type="range"
                    className="input"
                    value={ powerLevel }
                    onChange={ ev => setPowerLevel(parseInt(ev.target.value, 10)) }
                    min={ 0 }
                    max={ 11 }
                    step={ 1 }
                />
            </div>

            <div className="control">
                <span className="button is-static is-primary">
                    <span className="icon is-small is-left">
                        <i className="mdi mdi-lightning-bolt-circle"></i>
                    </span>
                    <span>
                        <PowerLevel value={ powerLevel } />
                    </span>
                </span>
            </div>

            <div className="control">
                <Shell plugin={ plugin } powerLevel={ powerLevel } />
            </div>
        </div>
    </div>;
};

const Shell = ({ plugin, powerLevel = 0, className = "" }) => {
    const fetch = useFetch();
    const mutation = useMutation({
        mutationKey: ['shell', plugin.environment.getName(), plugin.environment.project.getName()],
        mutationFn: () => plugin.invoke(fetch, {
            power_level: powerLevel || undefined,
        })
    });

    const shell = usePolling(
        ['shell', plugin.getARNList()[0]],
        mutation.isSuccess && (!mutation.data.ssh) && (() => plugin.environment.project.explore.invokePlugin(
            fetch,
            'read_shell',
            mutation.data.logs.arn,
            {
                task_name: mutation.data.logs.name,
            }))
    );
    const ssh = mutation.data?.ssh || shell.data;

    if (ssh) {
        return <div className={ classNames("field has-addons", className) } >
            <div className="control">
                <input readOnly className="input" type="text" value={ ssh } />
            </div>
            <div className="control">
                <CopyToClipboard text={ ssh } >
                    <button className="button is-danger">
                        <span>
                            Copy
                        </span>
                        <span className="icon">
                            <i className="mdi mdi-powershell"></i>
                        </span>
                    </button>
                </CopyToClipboard>
            </div>
        </div>;
    }

    return <span>
        <button className={ classNames("button is-primary is-danger", className, {
            "is-loading": mutation.isLoading
        }) }
        onClick={ mutation.mutate }
        disabled={ mutation.isLoading || shell.isLoading } >
            <span>
                Shell
            </span>
            <span className="icon">
                <i className="mdi mdi-powershell"></i>
            </span>
        </button>
    </span>;
};

const Aaaarrrr = ({ plugin }) => {
    const fetch = useFetch();
    const mutation = useMutation(() => plugin.invoke(fetch, plugin.getARNList()[0]));

    return <LongPressButton className={ classNames("button is-warning", {
        "is-loading": mutation.isLoading
    }) }
    onClick={ mutation.mutate }
    disabled={ mutation.isLoading } >
        <span>
            Aaaarrrr
        </span>
        <span className="icon">
            <i className="mdi mdi-pirate"></i>
        </span>
        <AaaaRrrrStatus project={ plugin.environment.project } />
    </LongPressButton>;
};

const Migrate = ({ plugin }) => {
    const fetch = useFetch();
    const mutation = useMutation(() => plugin.invoke(fetch, plugin.getARNList()[0]));
    const finished = usePolling(
        ['migrate', plugin.getARNList()[0]],
        mutation.isSuccess && (async () => {
            const { arn } = mutation.data;
            const waitTaskPlugin = new Plugin(plugin.environment, 'wait_task', [arn]);
            const resp = await waitTaskPlugin.invoke(fetch, arn);
            return resp.stopped ? resp : null;
        })
    );

    return <LongPressButton className={ classNames("button is-info", {
        "is-loading": mutation.isLoading
    }) }
    onClick={ mutation.mutate }
    disabled={ mutation.isLoading || finished.isLoading } >
        <span>
            Migrate
        </span>
        <span className="icon">
            <i className={ classNames("mdi", {
                'mdi-database-refresh': !finished.isLoading,
                'mdi-database-sync': finished.isLoading,
            }) } ></i>
        </span>
    </LongPressButton>;
};

const Autoscaling = ({
    plugin,
}) => plugin.getARNList().map(arn => <AutoscalingService plugin={ plugin } key={ arn } arn={ arn } />);

const autoscaling = (state, action) => {
    return {
        max: action.max !== undefined ? action.max : Math.max(action.min, state.max),
        min: action.min !== undefined ? action.min : Math.min(action.max, state.min),
        dirty: action.dirty !== undefined ? action.dirty : true,
    };
};


const initAutoscaling = (arn) => {
    const [min, max] = arn.split(':').pop().split('/');

    return {
        min,
        max,
        dirty: false,
    }
};

const AutoscalingService = ({
    arn,
    plugin,
}) => {
    const split = arn.split(':')
    const partition = split[1]
    const service = split[2]
    const name = split[5].split('/').pop();
    // If parition is k8s, we use dana plugin to get <resource>:<min>/<max> infos
    if(partition === 'k8s') {
        arn = plugin.environment.getPlugin('dana').getARNList().find(i => {
            arn = i.split(':')
            return arn[2] === service && arn[5].split('/').pop() === name
        })
    }
    
    const [state, dispatch] = React.useReducer(autoscaling, arn, initAutoscaling);
    const fetch = useFetch();

    const mutation = useMutation(() => plugin.invoke(fetch, arn, {
        min: state.min,
        max: state.max
    }), {
        onSuccess: () => dispatch({dirty: false}),
    });

    return <div>
        <label className="label">{ name }</label>
        <div className="field is-grouped">
            <div className="control">
                <button
                    onClick={ ev => dispatch({min: 0, max: 0 }) }
                    className={ classNames("button", {
                        "is-static": !state.min && !state.max,
                        "is-warning": state.min || state.max,
                    }) }
                >
                    <span className="icon is-right">
                        <i className="mdi mdi-power"></i>
                    </span>
                </button>
            </div>
            <div className="control has-icons-right">
                <input
                    className="input"
                    type="number"
                    onChange={ ev => dispatch({min: parseInt(ev.target.value) }) }
                    min="0"
                    value={ state.min } />
                <span className="icon is-right">
                    <i className="mdi mdi-arrow-down-box"></i>
                </span>
            </div>
            <div className="control has-icons-right">
                <input
                    className="input"
                    type="number"
                    onChange={ ev => dispatch({max: parseInt(ev.target.value) }) }
                    min="0"
                    value={ state.max } />
                <span className="icon is-right">
                    <i className="mdi mdi-arrow-up-box"></i>
                </span>
            </div>
        </div>
        <div className="field">
            <button
                className="button is-danger"
                onClick={ mutation.mutate }
                disabled={ !state.dirty }>
                Autoscale
            </button>
        </div>
    </div>;
}

const Noop = () => null;

const DBReadUser = ({
    plugin,
}) => {
    return plugin.getARNList().map(arn => <DBReadUserARN key={ arn } plugin={ plugin } arn={ arn } />);
}
const DBReadUserARN = ({
    plugin,
    arn,
}) => {
    const fetch = useFetch();
    const mutation = useMutation(() => plugin.invoke(fetch, arn));

    if (mutation.isSuccess) {
        const { Username, Password, Host, Port } = mutation.data;
        return <div className="field has-addons">
            <div className="control">
                <CopyToClipboard text={ Username } >
                    <button className="button">
                        { Username }:
                    </button>
                </CopyToClipboard>
            </div>
            <div className="control">
                <CopyToClipboard text={ Password } >
                    <button className="button">
                        <span>
                            Password
                        </span>
                        <span className="icon">
                            <i className="mdi mdi-form-textbox-password"></i>
                        </span>
                    </button>
                </CopyToClipboard>
            </div>
            <div className="control">
                <CopyToClipboard text={ Host } >
                    <button className="button">
                        @{Host}:{Port}
                    </button>
                </CopyToClipboard>
            </div>
        </div>;
    }

    const i1 = arn.lastIndexOf(':');
    const i2 = arn.indexOf('/', i1);
    const name = arn.substring(i2 + 1);
    return <button className={ classNames("button is-primary is-link", {
        "is-loading": mutation.isLoading
    }) }
    data-tooltip={ name }
    onClick={ mutation.mutate }
    disabled={ mutation.isLoading } >
        <span>
            DB Read user
        </span>
        <span className="icon">
            <i className="mdi mdi-database-arrow-up"></i>
        </span>
    </button>;
}

const PurgeCache = ({ plugin }) => {
    const fetch = useFetch();
    const mutation = useMutation(() => plugin.invoke(fetch, plugin.getARNList()[0]));

    return <LongPressButton className={ classNames("button is-warning", {
        "is-loading": mutation.isLoading
    }) }
    onClick={ mutation.mutate }
    disabled={ mutation.isLoading } >
        <span data-tooltip={ plugin.getARNList()[0] } >
            Purge cache
        </span>
        <span className="icon">
            <i className="mdi mdi-fire"></i>
        </span>
    </LongPressButton>;
};

const ButtonPluginsComponents = {
    shell: Shell,
    aaaarrrr: Aaaarrrr,
    deploy: Deploy,
    migrate: Migrate,
    promote_image: PromoteImage,
    dbreaduser: DBReadUser,
    purge_cache: PurgeCache,
};
const ViewPluginsComponents = {
    dana: Noop,
    version: Noop,
    wait_task: Noop,
    dbreaduser: Noop,
    read_shell: Noop,
    deployment: Noop,
    set_scale: Autoscaling,
    shell: ShellWithPowerlevel,
    tasks: Noop,
    aaaarrrr: Noop,
    noop: Noop,
    deploy: FullDeploy,
    migrate: Noop,
};

const DefaultPlugin = ({
    plugin,
}) => {
    const [arn, setArn] = React.useState('');
    const fetch = useFetch();
    const mutation = useMutation(() => plugin.invoke(fetch, arn));

    return <div className="field has-addons">
        <div className="control is-expanded">
            <div className="select is-fullwidth">
                <select value={ arn } onChange={ ({ target }) => setArn(target.value) } >
                    <option>{ plugin.getName() }</option>
                    { plugin.getARNList().map(arn => <option value={ arn } key={ arn }>{arn}</option>) }
                </select>
            </div>
        </div>
        <div className="control">
            <button className={ classNames("button is-warning", {
        "is-loading": mutation.isLoading
    }) }
    onClick={ mutation.mutate }
    disabled={ !arn || mutation.isLoading } >
                Go!
            </button>
        </div>
    </div>;
};

const getComponentButton = plugin => ButtonPluginsComponents[plugin.getName()];
const getComponentView = plugin => ViewPluginsComponents[plugin.getName()] || DefaultPlugin;

export const PluginList = ({ buttons, plugins }) => {
    const factory = buttons ? getComponentButton : getComponentView;
    const components = [];
    for (const plugin of plugins) {
        const Component = factory(plugin);
        if (Component) {
            components.push(<Component plugin={ plugin } key={ plugin.getName() } />);
        }
    }
    return components;
}

