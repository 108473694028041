import React from 'react';


const useDebouncedPushState = () => {
    const ref = React.useRef(null);

    React.useEffect(() => {
        return () => {
            if (ref.current) {
                clearTimeout(ref.current);
            }
        };
    }, []);

    return React.useCallback(value => {
        if (ref.current) {
            clearTimeout(ref.current);
        }

        ref.current = setTimeout(() => {
            const url = new URL(window.location);
            const query = url.hash = decodeURIComponent(value);
            window.history.pushState({ query }, "", url);

            ref.current = null;
        }, 300);
    }, []);
};

export const useLocationSearch = (initial) => {
    const [state, setState] = React.useState(window.history.state?.query || '');
    const addNavigation = useDebouncedPushState();

    const setLocationSearch = React.useCallback((value) => {
        setState(value);
        addNavigation(value);
    }, [setState, addNavigation]);

    React.useEffect(() => {
        const listener = ({ state }) => {
            setState((state.query || "").trimStart("?"));
        };
        window.addEventListener('popstate', listener);
        return () => {
            window.removeEventListener('popstate', listener);
        };
    }, [setState]);

    return [state, setLocationSearch];
};
