import invariant from "invariant";

export class InvokationError extends Error {}

export class InvokationFailed extends Error {
    constructor(status, value) {
        super(JSON.stringify(value, 4));
        this.status = status;
        this.value = value;
    }
}

export class Aaaarrrr {
    static async query(fetch) {
        const httpGET = await fetch('/aaaarrrr');
        if (!httpGET.ok) {
            throw new Error(await httpGET.text());
        }
        const values = await httpGET.json();
        return new Aaaarrrr(values);
    }

    constructor(data) {
        this.days = data.days;
        this.calendar = data.calendar;
        this.executions = data.executions;
    }

    describeExecution(id) {
        return this.executions[id] ? new Execution(this.executions[id]) : Execution.skipped();
    }

    getCalendars() {
        return Object.keys(this.calendar)
            .sort()
            .map(name => new Calendar(this, name, this.calendar[name]));
    }
}

export class Execution {
    static skipped() {
        return new Execution({ status: 'skipped' })
    }

    constructor({ status, cause }) {
        this._status = status;
        this.cause = cause || '';
    }

    get status() {
        return this._status.toLowerCase();
    }
}

export class Calendar {
    constructor(aaaarrrr, name, data) {
        this.aaaarrrr = aaaarrrr;
        this.name = name;
        this.data = data;
    }

    getName() {
        return this.name;
    }

    getMachines() {
        return Object.keys(this.data)
            .sort()
            .map(name => new StateMachine(this.aaaarrrr, name, this.data[name]));
    }
}

export class StateMachine {
    constructor(aaaarrrr, name, data) {
        this.aaaarrrr = aaaarrrr;
        this.name = name;
        this.data = data;
    }

    getName() {
        return this.name;
    }

    getDays() {
        return this.data.history;
    }
}

export class Explore {
    static async query(fetch) {
        const httpGET = await fetch('/explore');
        if (!httpGET.ok) {
            throw new Error(await httpGET.text());
        }
        const values = await httpGET.json();
        const lambdaName = httpGET.headers.get('x-lambda-name');
        return new Explore(values, lambdaName);
    }

    constructor(data, lambdaName) {
        this.data = data;
        this.lambdaName = lambdaName;
    }

    getProjects() {
        return Object.keys(this.data)
            .sort()
            .map(name => new Project(this, name, this.data[name]));
    }

    async invokePlugin(fetch, name, arn, values) {
        invariant(name, 'name is required');
        invariant(arn, 'arn is required');

        const response = await fetch(`/do/${name}/${arn}`, {
            method: 'POST',
            body: values ? JSON.stringify(values) : '',
            headers: {
                'Content-Type': values ? 'application/json' : undefined,
            }
        });

        if (!response.ok &&  response.status === 500) {
            throw new InvokationError(await response.text());
        }
        if (response.headers.get('content-type') === 'application/json') {
            const value = await response.json();
            if (!response.ok) {
                throw new InvokationFailed(response.status, value);
            }
            return value;
        }
        return response.text();
    }
}

export class Project {
    constructor(explore, name, data) {
        this.explore = explore;
        this.name = name;
        this.data = data;

        this.repo = new Environment(this, 'repo', data.repo || {});

        // List all the environments of the project.
        // Fist list all the environment having at least a plugin
        const envs = new Set(Object.keys(data));
        // Env repo is handled differently
        envs.delete('repo');
        // latest is not quite an env
        envs.delete('latest');
        // Remove aaaarrrr envs
        envs.delete('restore');
        envs.delete('archive');

        // Create from the list of the names the Environment instance
        this._environments = Array.from(envs.values())
            .sort()
            .reverse()
            .map(name => new Environment(this, name, data[name] || {}));

        const relevantBranches = this._environments
            .map(e => e.versions)
            .flat()
            .map(v => v.branch);

        // Build the set of relevant branches, it is the set of branches
        // referenced in the packages of the project
        this._relevantBranches = new Set(relevantBranches.concat(
            relevantBranches.map(b => b.replace('master', 'latest'))
        ));
    }

    getName() {
        return this.name;
    }

    getEnvironments() {
        return this._environments;
    }

    getRelevantBranches() {
        return this._relevantBranches;
    }
}


export class Environment {
    constructor(project, name, data) {
        this.project = project;
        this.name = name;
        this.data = data;

        this.plugins = Object.keys(this.data)
            .sort()
            .map(name => new Plugin(this, name, this.data[name]));

        this.packages = (this.getPlugin('package')?.getARNList() || [])
            .map(v => new Package(this.project, v));
        this.packagesIndex = this.packages.reduce((memo, packag) => {
            const s = memo[packag.service] = memo[packag.service] || {};
            const t = s[packag.index] = s[packag.index] || {};
            t[packag.branch] = packag;
            return memo;
        }, {});

        this.versions = (this.getPlugin('version')?.getARNList() || [])
            .map(v => new Version(this.project, v));
    }
    getName() {
        return this.name;
    }

    hasPlugin(pl) {
        return Boolean(this.data[pl]);
    }

    getPlugin(name) {
        return this.plugins.find(pl => pl.getName() === name);
    }

    getPlugins() {
        return this.plugins;
    }

    getPackages() {
        const rb = this.project.getRelevantBranches();
        return this.packages.filter(p => rb.has(p.branch));
    }

    getVersions() {
        return this.versions;
    }

    findVersion(arn) {
        const colon1 = 'arn'.length;
        const colon2 = arn.indexOf(':', colon1 + 1);
        const colon3 = arn.indexOf(':', colon2 + 1);
        const colon4 = arn.indexOf(':', colon3 + 1);
        const colon5 = arn.indexOf(':', colon4 + 1);

        const service = arn.substring(1 + colon2, colon3);
        const instance = arn.substring(1 + colon5);
        const aliasInstance = `${service}:${instance}`

        return this.versions.find(v => {
            return (
                (v.service === service && v.instance === instance) ||
                v.instance === aliasInstance
            );
        });
    }

    isLatest(version) {
        const serviceIndex = this.packagesIndex[version.service]
        if (!serviceIndex) {
            return null;
        }
        const index = serviceIndex[version.package];
        // There is a matching package
        if (index) {
            const packag = (
                index[`branch.${version.branch}`] ||
                index[version.branch] ||
                index.master ||
                index.latest
            );
            return packag.version === version.version;
        }
        // Explore each service and find if there is a match for known names
        for (const service of Object.values(serviceIndex)) {
            for (const name of [`branch.${version.branch}`, version.branch, 'master', 'latest']) {
                if (service[name]?.version === version.version) {
                    return true;
                }
            }
        }
        return false;
    }
}

class Package {
    constructor(project, arn) {
        this.project = project;
        // arn:package:s3:danaa:master:f35
        const [,, service, index, branch, version] = arn.split(':');
        this.service = service;
        this.index = index;
        this.branch = branch;
        this.version = version;
    }

    toString() {
        return `${this.branch} #${this.version}`;
    }

    getName() {
        return `${this.index} (${this.service})`;
    }

    getBranchName() {
        return this.branch === 'latest' ? 'master' : this.branch;
    }

    getGithubDiffLink(ref) {
        ref = ref || 'master';
        return `https://github.com/Weezevent/${this.project.getName()}/` +
        `compare/${this.getBranchName()}...${ref}`;
    }

    getGithubLink() {
	    return `https://github.com/Weezevent/${this.project.name}/tree/${this.getBranchName()}`;
    }
}

class Version {
    constructor(project, arn) {
        this.project = project;

        const colon2 = 'arn:version:'.length;
        const colon3 = arn.indexOf(':', colon2);

        const colon4 = arn.indexOf(':', colon3 + 1);
        const colon5 = arn.indexOf(':', colon4 + 1);

        const lastColon1 = arn.lastIndexOf(':');
        const lastColon2 = arn.lastIndexOf(':', lastColon1 - 1);
        const lastColon3 = arn.lastIndexOf(':', lastColon2 - 1);

        this.service = arn.substring(colon2, colon3);
        this.instance = arn.substring(colon5 + 1, lastColon3);
        this.branch = arn.substring(lastColon1, lastColon2 + 1) || 'master';
        this.package = arn.substring(lastColon2, lastColon3 + 1) || this.project.name;
        this.version = arn.substring(lastColon1 + 1);
    }

    isLatest() {
        return this.project.repo.isLatest(this);
    }

    toString() {
        return `#${this.version}@${this.branch}`;
    }

    getName() {
        return `${this.instance} (${this.service})`;
    }

    getGithubLink() {
	    return `https://github.com/Weezevent/${this.project.getName()}/releases/tag/t${this.version}`;
    }

    getGithubDiffLink() {
        return `https://github.com/Weezevent/${this.project.getName()}/` +
        `compare/t${this.version}...${this.branch}`;
    }
}

export class Plugin {
    constructor(environment, name, arns) {
        this.environment = environment;
        this.name = name;
        this.arns = arns;
    }

    getName() {
        return this.name;
    }

    getARNList() {
        return this.arns.sort().reverse()
    }

    invoke(fetch, arn, values) {
        if (typeof arn !== 'string' && values === undefined) {
            values = arn;
            arn = this.arns[0];
        }

        return this.environment.project.explore.invokePlugin(fetch, this.name, arn, values);
    }
}
