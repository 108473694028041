import React from 'react';
import { InvokationError } from "./models";

const context = React.createContext({});

const Provider = props => {
    const [errors, setErrors] = React.useState([]);
    const value = {
        record: (error) => {
            if (error instanceof InvokationError) {
                setErrors(current => current.concat([error]))
            }
        },
        clear: () => setErrors([]),
        errors,
    };
    return <context.Provider {...props} value={ value } />
}

export const ErrorContext = {
    useInstance: () => React.useContext(context),
    Provider,
}

export const ExploreError = ({ error, onClick }) => {
    return <section className="hero is-danger">
        <div className="hero-body">
            <p className="title">
                { error.constructor.name || 'Error' }
            </p>
            <p className="subtitle">
                <pre onClick={ onClick }>
                    { error.toString() }
                </pre>
            </p>
        </div>
    </section>;
}

export const Errors = () => {
    const errorManager = ErrorContext.useInstance();
    return errorManager.errors.map(error => <article className="message is-danger">
        <div className="message-header">
            <p>Error</p>
            <button
                className="delete"
                aria-label="delete"
                onClick={ () => errorManager.clear() }
            ></button>
        </div>
        <div className="message-body">
            <pre>{ String(error) }</pre>
        </div>
    </article>)
}
